/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 36번째 로그에서는 @jayjinjay 님과 함께 오픈핵 2019, Little Big Data, Facebook Dev C 서울, Oculus Quest 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/openhack.kr/photos/a.1618623918200706/2449038431825913/?type=3&theater"
  }, "Openhack 2019 - 페이스북")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.korea.kr/news/pressReleaseView.do?newsId=156324532&call_from=rsslink"
  }, "2019년도 소프트웨어 중심대학 5개교 최종 선정 - 대한민국 정책브리핑")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://milooy.wordpress.com/"
  }, "Jay Jin, Programmer&Designer – Fork my brain because I'm ready to commit")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://zeppelin.apache.org/"
  }, "Zeppelin")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.zepl.com/"
  }, "Data Science Analytics Platform | Zepl")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://zeplin.io/"
  }, "Zeplin")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://sigoss.github.io/hackathon2018/index.html"
  }, "2018 소프트웨어 중심대학 오픈소스 해커톤")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/OrangeTen/TodayILearned"
  }, "OrangeTen/TodayILearned")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://hotel.westernlife.co.kr/#"
  }, "웨스턴 라이프 호텔")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://programmers.co.kr/competitions/59/2018-summercoding-%EC%97%AC%EB%A6%84%EB%B0%A9%ED%95%99-%EC%8A%A4%ED%83%80%ED%8A%B8%EC%97%85-%EC%9D%B8%ED%84%B4-%ED%94%84%EB%A1%9C%EA%B7%B8%EB%9E%A8"
  }, "2018 SummerCoding - 여름방학 스타트업 인턴 프로그램 | 프로그래머스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/NAVER-CAMPUS-HACKDAY/common"
  }, "2019 NAVER CAMPUS HACKDAY SUMMER 행사안내")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/318"
  }, "Seocho.rb 두 번째 모임 | Festa!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://goree.io/events/3"
  }, "Planet Hackathon 2018 by GDG x 9XD - 사이를 잇는 Goree")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/cowx2ater/photos/a.156948108323045/319432745407913/?type=3&theater"
  }, "뉴비톤 2019: 우물 128번지 - 페이스북")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/281"
  }, "제 1회 스포카 해커톤: ㅁㅆㅁㅌ | Festa!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.culture.go.kr/wday/index.do"
  }, "문화가 있는 날")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/jay.jin.0427/posts/2185428664903388?__tn__=-R"
  }, "진유림 - 발표자 신청 오픈  Data Science is a Team Sports! === Big 데이터는... | 페이스북")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/dataitgirls/"
  }, "데잇걸즈 - 페이스북")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://datayanolja.github.io/2018-datayanolja/"
  }, "데이터야놀자 메인페이지")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/21"
  }, "Little Big Data #1 : 다양한 사람들의 데이터 사이언스 이야기 | Festa!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://speakerdeck.com/nacyot/yasin-peurojegteu-yagu-deiteo-sigaghwa-peurojegteu"
  }, "야신 프로젝트 - 야구 데이터 시각화 프로젝트 - Speaker Deck")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=oKg1hTOQXoY"
  }, "Alan Kay at OOPSLA 1997 - The computer revolution hasnt happened yet - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stdout.fm/31/"
  }, "stdout_031.log: Facebook F8 2019, 핀테크 업체의 금융 정보 스크래핑 w/ outsider | 개발자 팟캐스트 stdout.fm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/groups/DevCSeoul/"
  }, "Facebook Developer Circle: Seoul")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://devcpangyomeetup.splashthat.com/"
  }, "판교 DevC 번개")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bloter.net/archives/341802"
  }, "‘F8 2019 해커톤’이 우리에게 남긴 것은….")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developers.facebook.com/blog/post/2019/02/20/f8-2019-registration-now-open/"
  }, "F8 2019 Registration Now Open")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.oculus.com/go/"
  }, "Oculus Go")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.oculus.com/quest/"
  }, "Oculus Quest")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.vrchat.net/"
  }, "VRChat")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://youtu.be/qdcricoPmRI?t=891"
  }, "Facebook Zuckerberg Oculus Iribe OC3 Keynote (FULL) Social VR, Touch, ReactVR - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://beatsaber.com/"
  }, "Beat Saber - VR rhythm game")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?time_continue=92&v=05pzUXujMJU"
  }, "Beat Saber with Brie Larson - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.h-vrstation.com/main"
  }, "VR STATION")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://movie.daum.net/moviedb/main?movieId=96030"
  }, "레디 플레이어 원 - Daum 영화")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developers.facebook.com/developercircles?locale=ko_KR"
  }, "Developer Circles - 홈")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/groups/DevCSeoul/"
  }, "Facebook Developer Circle: Seoul")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/"
  }, "Festa! 이벤트를 만나는 가장 쉬운 방법")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://splashthat.com/"
  }, "Event Marketing Software - Splash")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Unconference"
  }, "Unconference - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Ignite_%28event%29"
  }, "Ignite (event) - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://confconf.github.io/"
  }, "ConfConf 2016")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/groups/DevCSeoul/permalink/2285805818107444/"
  }, "We’re just ordinary IT people - Facebook Developer Circle: Seoul")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://sites.google.com/site/anpshare/anpedia/altlang"
  }, "대안언어축제 - anpshare")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://espressobook.com/"
  }, "Ebook self-publishing | Free book making service | EspressoBook | EspressoBook")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.realhanbit.co.kr/books/125/pages/1128/preview"
  }, "초심자를 위한 Github 협업 | IT 전자책 출판 서비스 리얼타임 Web Book 베타")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sourcetreeapp.com/"
  }, "Sourcetree | Free Git GUI for Mac and Windows")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://desktop.github.com/"
  }, "GitHub Desktop | Simple collaboration from your desktop")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://magit.vc/"
  }, "It's Magit! A Git Porcelain inside Emacs")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.yes24.com/Product/Goods/22906810?scode=032&OzSrank=1"
  }, "프로그래밍 루비 - YES24")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/events/212947969654181/?active_tab=discussion"
  }, "개발자, 한 달에 책 한 권 읽기 - 함께 자라기")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://meetgo.kr/?fbclid=IwAR3WIumzwu9W-_mXlN2RnqXTtlTmI2Xj2LQPWbSqpdCRADeMYGwcFydIJgo#/userEventDetail/june"
  }, "Meetgo :: 오프라인 모임 플랫폼 밋고")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://search.naver.com/search.naver?sm=top_hty&fbm=1&ie=utf8&query=stdout.fm"
  }, "stdout.fm : 네이버 통합검색")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.google.co.kr/search?q=stdout&newwindow=1&safe=off&hl=ko&tbas=0&source=lnt&sa=X&ved=0ahUKEwiy_aTB1pHjAhXFL6YKHbUeDLUQpwUIJA&biw=1112&bih=1265&dpr=2"
  }, "stdout - Google 검색")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
